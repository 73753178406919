import { useCallback, useMemo, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";

import ConfirmationModal from "components/modals/confirmation-modal/confirmation-modal";
import Select from "components/forms/select/select";
import Input from "components/forms/input/input";
import toast from "components/partials/toast/toast";

import { useCurrentClient, useCurrentCampaign, useCurrentTouchpoint } from "state/ducks";
import TouchpointVersion, { TouchpointVersionAttributes } from "models/touchpoint-version";

import { useClientCampaigns } from "hooks/use-client-campaigns";
import { getErrorMessage } from "utilities";
import { constructPath, Route as AppRoute } from "utilities/app-routes";

import { TouchpointType } from "types/touchpoint";

interface CloneTouchpointModalProps {
  isOpen: boolean;
  onClose: () => void;
  selectedVersion?: TouchpointVersionAttributes;
}

interface CloneFormData {
  campaignId: string;
  touchpointName: string;
}

export const CloneTouchpointModal = ({
  isOpen,
  onClose,
  selectedVersion,
}: CloneTouchpointModalProps) => {
  const history = useHistory();
  const currentClient = useCurrentClient();
  const currentCampaign = useCurrentCampaign();
  const currentTouchpoint = useCurrentTouchpoint();
  const clientCampaigns = useClientCampaigns();

  const defaultTouchpointName = `Copy of ${selectedVersion?.name ?? currentTouchpoint.name}`;

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors, isValid },
  } = useForm<CloneFormData>({
    mode: "onChange",
    defaultValues: {
      campaignId: currentCampaign.id,
      touchpointName: defaultTouchpointName,
    },
  });

  const sortedCampaigns = useMemo(() => {
    return [...clientCampaigns].sort((a, b) => a.name.localeCompare(b.name));
  }, [clientCampaigns]);

  const abortControllerRef = useRef<AbortController | null>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    return () => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
    };
  }, []);

  useEffect(() => {
    if (isOpen) {
      reset({
        campaignId: currentCampaign.id,
        touchpointName: defaultTouchpointName,
      });
    }
  }, [
    isOpen,
    currentCampaign.id,
    selectedVersion,
    currentTouchpoint.name,
    defaultTouchpointName,
    reset,
  ]);

  const handleSubmitCloneTouchpoint = useCallback(
    (data: CloneFormData) => {
      if (!selectedVersion) return;

      const getTypeUrlSegment = (type: TouchpointType) => {
        switch (type) {
          case TouchpointType.EMAIL:
            return AppRoute.emails;
          case TouchpointType.LANDING_PAGE:
            return AppRoute.landingPages;
          case TouchpointType.THEME:
            return AppRoute.themes;
        }
      };

      abortControllerRef.current = new AbortController();
      const signal = abortControllerRef.current.signal;

      TouchpointVersion.clone({
        campaignId: data.campaignId,
        clientId: currentClient.id,
        touchpointId: currentTouchpoint.id,
        id: selectedVersion?.id,
        name: data.touchpointName,
      })
        .then(() => {
          if (signal.aborted) return;

          const selectedCampaign = clientCampaigns.find(
            (campaign) => campaign.id === data.campaignId
          );
          toast.success({
            title: `The touchpoint is cloned to the ${selectedCampaign?.name} campaign.`,
          });

          const touchpointType = getTypeUrlSegment(currentTouchpoint.type);

          const campaignCreativeTablePath = constructPath(
            AppRoute.clients,
            currentClient.id,
            AppRoute.campaigns,
            data.campaignId,
            AppRoute.creative,
            touchpointType
          );

          history.push({
            pathname: campaignCreativeTablePath,
          });
        })
        .catch((err) => {
          if (signal.aborted) return;

          toast.error({
            title: "Failed to clone Touchpoint",
            message: getErrorMessage(err?.response?.data),
          });
        })
        .finally(() => {
          if (!signal.aborted) {
            onClose();
          }
          abortControllerRef.current = null;
        });
    },
    [
      clientCampaigns,
      currentClient.id,
      currentTouchpoint.id,
      currentTouchpoint.type,
      history,
      onClose,
      selectedVersion,
    ]
  );

  return (
    <ConfirmationModal
      isOpen={isOpen}
      onClose={onClose}
      modalType="warning"
      headline="Clone Touchpoint"
      message="Please choose the campaign where you want to clone this touchpoint."
      confirmButtonText="Clone Touchpoint"
      onConfirm={handleSubmit(handleSubmitCloneTouchpoint)}
      disabled={!isValid}>
      <form className="flex flex-col gap-2" onSubmit={handleSubmit(handleSubmitCloneTouchpoint)}>
        <Controller
          name="campaignId"
          control={control}
          rules={{ required: "Campaign name is required" }}
          render={({ field }) => (
            <Select label="Campaign name" isRequired {...field}>
              {sortedCampaigns.map((campaign) => (
                <option key={campaign.id} value={campaign.id}>
                  {campaign.name}
                </option>
              ))}
            </Select>
          )}
        />
        <Controller
          name="touchpointName"
          control={control}
          rules={{ required: "Touchpoint name is required" }}
          render={({ field: { ref, ...restField } }) => (
            <Input
              label="Touchpoint name"
              isRequired
              errorMessage={errors.touchpointName?.message}
              {...restField}
              ref={inputRef}
            />
          )}
        />
      </form>
    </ConfirmationModal>
  );
};
