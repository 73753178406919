const Input = {
  variants: {
    outline: {
      field: {
        backgroundColor: "forms.inputBg",
        border: "1px solid transparent",
        color: "forms.inputColor",
        _disabled: {
          backgroundColor: "forms.inputDisabledBg",
          cursor: "not-allowed",
        },
        _focus: { boxShadow: "outline" },
        _hover: {
          borderColor: "inherit",
        },
        _invalid: {
          borderColor: "forms.errorBorderColor",
        },
        _placeholder: { color: "text.muted" },
      },
    },
  },
  defaultProps: {
    size: "md",
    variant: "outline",
  },
};

const FormLabel = {
  baseStyle: {
    color: "text.body",
    fontWeight: "bold",
    opacity: "1!important",
  },
};

const Popover = {
  variants: {
    responsive: {
      popper: {
        maxWidth: "unset",
        width: "unset",
      },
    },
  },
};

const components = {
  Input,
  FormLabel,
  NumberInput: {
    ...Input,
  },
  Popover,
};

export default components;
