import { FunctionComponent, SVGProps } from "react";
import { twMerge } from "tailwind-merge";

// actions
import { ReactComponent as Airplane } from "assets/icons/airplane.svg";
import { ReactComponent as Archive } from "assets/icons/archive.svg";
import { ReactComponent as ChevronDown } from "assets/icons/chevron-down.svg";
import { ReactComponent as Close } from "assets/icons/close.svg";
import { ReactComponent as CopyDocument } from "assets/icons/document-copy.svg";
import { ReactComponent as Document } from "assets/icons/document.svg";
import { ReactComponent as Edit } from "assets/icons/edit.svg";
import { ReactComponent as Ellipsis } from "assets/icons/ellipsis.svg";
import { ReactComponent as EyeHidden } from "assets/icons/eye-hidden.svg";
import { ReactComponent as EyeVisible } from "assets/icons/eye-visible.svg";
import { ReactComponent as Gear } from "assets/icons/gear.svg";
import { ReactComponent as Hamburger } from "assets/icons/hamburger.svg";
import { ReactComponent as Info } from "assets/icons/info.svg";
import { ReactComponent as NewDocument } from "assets/icons/document-new.svg";
import { ReactComponent as Plus } from "assets/icons/plus.svg";
import { ReactComponent as Search } from "assets/icons/search.svg";
import { ReactComponent as Upload } from "assets/icons/upload.svg";

// indicators
import { ReactComponent as ErrorIndicator } from "assets/icons/indicator-error.svg";
import { ReactComponent as InfoIndicator } from "assets/icons/indicator-info.svg";
import { ReactComponent as RequiredIndicator } from "assets/icons/required-indicator.svg";
import { ReactComponent as SuccessIndicator } from "assets/icons/indicator-success.svg";
import { ReactComponent as WarningIndicator } from "assets/icons/indicator-warning.svg";

// images
import { ReactComponent as ApolloLogo } from "assets/images/apollo-logo.svg";
import { ReactComponent as TwoOceanLogo } from "assets/images/two-ocean-logo.svg";

export const icons = {
  Airplane,
  Archive,
  ChevronDown,
  Close,
  CopyDocument,
  Document,
  Edit,
  Ellipsis,
  EyeHidden,
  EyeVisible,
  Gear,
  Hamburger,
  Info,
  NewDocument,
  Plus,
  Search,
  Upload,
  ErrorIndicator,
  InfoIndicator,
  RequiredIndicator,
  SuccessIndicator,
  WarningIndicator,
};

export const images = {
  ApolloLogo,
  TwoOceanLogo,
};

export type IconName = keyof typeof icons | keyof typeof images;

export interface SVGIconProps extends Omit<SVGProps<SVGSVGElement>, "ref"> {
  customClass?: string;
  className?: string;
  /**
   * @deprecated Icons should not have a title attribute. Put it on the parent.*/
  title?: string;
  /**
   * @deprecated Icons can't make use of the alt attribute. */
  alt?: string;
  /**
   * @deprecated Icons should not have an onClick attribute. Put the icon inside a button.  */
  onClick?: () => void;
}

type IconComponent = FunctionComponent<SVGIconProps>;

const createIconComponent = (Icon: FunctionComponent<SVGProps<SVGSVGElement>>): IconComponent => {
  return ({ customClass, className, onClick, ...rest }: SVGIconProps): JSX.Element => (
    <Icon className={twMerge(customClass, className)} onClick={onClick} {...rest} />
  );
};

const Icons = Object.fromEntries(
  [...Object.entries(icons), ...Object.entries(images)].map(([key, value]) => [
    key,
    createIconComponent(value as FunctionComponent<SVGProps<SVGSVGElement>>),
  ])
) as { [K in IconName]: IconComponent };

export default Icons;
