import React from "react";
import {
  Flex,
  FormControl,
  Textarea as ChakraTextarea,
  TextareaProps as ChakraTextareaProps,
  forwardRef,
} from "@chakra-ui/react";

import { Small } from "components/partials/typography/typography";
import Label from "components/forms/label/label";
import HelperText from "components/forms/helper-text/helper-text";
import { InputControlProps } from "components/forms/input-control/input-control";

interface TextareaProps extends ChakraTextareaProps, InputControlProps {
  value: string;
  rows?: number;
  characterLimit?: number;
}

const Textarea = forwardRef<TextareaProps, "textarea">(
  (
    {
      value,
      label,
      placeholder = "",
      helperText,
      errorMessage,
      isDisabled = false,
      rows = 5,
      characterLimit,
      ...rest
    },
    ref
  ) => {
    const TextareaHeader = () => {
      function getRemainingCharacters(characterLimit: number) {
        return (characterLimit - value.length).toLocaleString();
      }
      return (
        <Flex justifyContent="space-between" align="baseline">
          {label && <Label value={label} />}
          {characterLimit && (
            <Small>{getRemainingCharacters(characterLimit)} characters remaining</Small>
          )}
        </Flex>
      );
    };
    // TODO: Replace `FormControl` with `InputControl`
    return (
      <FormControl isInvalid={!!errorMessage} isDisabled={isDisabled}>
        {(label || characterLimit) && <TextareaHeader />}
        <ChakraTextarea
          placeholder={placeholder}
          bg="white"
          size="lg"
          rows={rows}
          maxLength={characterLimit}
          value={value}
          ref={ref}
          {...rest}
        />
        {helperText && <HelperText message={helperText as string} />}
      </FormControl>
    );
  }
);

export default Textarea;
