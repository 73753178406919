import { FormControl, FormControlOptions } from "@chakra-ui/react";

import Label from "components/forms/label/label";
import HelperText, { HelpMessage } from "components/forms/helper-text/helper-text";
import ErrorMessage from "components/forms/error-message/error-message";
import WarningMessage from "components/forms/warning-message/warning-message";

/** Includes `isDisabled`, `isInvalid`, `isReadOnly`, `isRequired`*/
interface BooleanFormControlOptions extends FormControlOptions {}

export interface InputControlProps extends BooleanFormControlOptions {
  children?: React.ReactNode;
  errorMessage?: HelpMessage;
  helperText?: HelpMessage;
  id?: string;
  label?: string;
  labelColor?: string;
  warningMessage?: HelpMessage;
}

export const InputControl = ({
  children,
  errorMessage,
  helperText,
  isInvalid,
  label,
  labelColor,
  warningMessage,
  ...rest
}: InputControlProps) => {
  return (
    <FormControl isInvalid={!!errorMessage || isInvalid} {...rest}>
      {label && <Label color={labelColor} value={label} />}
      {children}
      <ErrorMessage message={errorMessage} />
      <HelperText message={helperText} />
      <WarningMessage message={warningMessage} />
    </FormControl>
  );
};

/**
 * Separates FormControl props from Input props. Example:
 * ```
 * const { controlProps, inputProps } = useInputProps(props);
 *  return (
 *    <InputControl {...controlProps}>
 *      <Input {...inputProps} />
 *    </InputControl>
 *  )
 * ``` */
export function useInputProps(props: Record<string, any>) {
  const controlProps: InputControlProps = {
    errorMessage: props.errorMessage,
    helperText: props.helperText,
    id: props.id,
    isDisabled: props.isDisabled,
    isReadOnly: props.isReadOnly,
    isRequired: props.isRequired,
    label: props.label,
    labelColor: props.labelColor,
    warningMessage: props.warningMessage,
  };

  /**
   * Return an objects with all properties except those in `controlProps`  */
  const getInputProps = (): Record<string, any> => {
    for (const key in controlProps) {
      if (props.hasOwnProperty(key)) {
        delete props[key];
      }
    }
    return props;
  };

  const inputProps = getInputProps();

  return { controlProps, inputProps };
}
