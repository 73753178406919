import { useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { HStack, MenuButton, useDisclosure, Menu, MenuList, Box } from "@chakra-ui/react";

import MenuItem from "components/partials/menu-item/menu-item";
import PaginatedTable from "components/partials/paginated-table/paginated-table";
import {
  TableEditButton,
  TableMoreButton,
  TableViewButton,
} from "components/table/table-icon-button/table-icon-button";
import VersionEditModal, {
  onSubmitFormProps,
  FormType,
} from "components/modals/version-edit-modal/version-edit-modal";
import ConfirmArchiveModal from "components/modals/confirm-archive-modal/confirm-archive-modal";
import { StatusColumn } from "components/table/table-status-column/table-status-column";
import ConfirmationModal from "components/modals/confirmation-modal/confirmation-modal";
import toast from "components/partials/toast/toast";
import { ClientReviewStatusColumn } from "components/table/table-columns/table-columns";
import { TableCell } from "components/table/table-cell/table-cell";
import { TableRow } from "components/table/table";

import {
  useCurrentClient,
  useCurrentCampaign,
  useCurrentTouchpoint,
  useCurrentUser,
} from "state/ducks";
import { setTouchpointVersions } from "state/ducks/touchpoint";
import TouchpointVersion, { TouchpointVersionAttributes } from "models/touchpoint-version";
import Touchpoint from "models/touchpoint";

import { Route as AppRoute } from "utilities/app-routes";
import { getDisplayTouchpointType, getErrorMessage, getTouchpointType } from "utilities";
import { hasPermission } from "utilities/user";

import { Permission } from "types/auth";
import { ContentStatus } from "types";
import { TouchpointType } from "types/touchpoint";
import { PaginatedRequestOptions, PaginatedResponse } from "types/pagination";
import { CloneTouchpointModal } from "components/modals/clone-touchpoint-modal/clone-touchpoint-modal";

import { IconName } from "assets";

interface TouchpointVersionTableProps {
  baseCreativeUrl: string;
  touchpointType: TouchpointType;
  creativeName: string;
}

function TouchpointVersionTable({
  baseCreativeUrl,
  touchpointType,
  creativeName,
}: Readonly<TouchpointVersionTableProps>) {
  const history = useHistory();
  const currentClient = useCurrentClient();
  const currentUser = useCurrentUser();
  const currentCampaign = useCurrentCampaign();
  const currentTouchpoint = useCurrentTouchpoint();
  const touchpointNewDraftModal = useDisclosure();
  const touchpointEditDraftModal = useDisclosure();
  const confirmArchiveModal = useDisclosure();
  const cloneTouchpointModal = useDisclosure();
  const confirmPublishModal = useDisclosure();
  const dispatch = useDispatch();

  // TODO: currentActionItem should be just the touchpoint version ID
  // Then we can pull the touchpoint version from Redux using that ID
  const [currentActionItem, setCurrentActionItem] = useState<TouchpointVersion>();
  const [nextMinorMajorVersions, setNextMinorMajorVersions] = useState<string[]>();
  const [shouldRefreshTable, setShouldRefreshTable] = useState<boolean>(false);
  const refreshTable = () => setShouldRefreshTable(true);

  const [versionToClone, setVersionToClone] = useState<TouchpointVersionAttributes>();

  const loadVersionHistory = (
    options: PaginatedRequestOptions
  ): Promise<PaginatedResponse<TouchpointVersion>> => {
    return TouchpointVersion.all({
      clientId: currentClient.id,
      campaignId: currentCampaign.id,
      touchpointId: currentTouchpoint.id,
      options: { ...options, sort: "desc" },
    }).then((results) => {
      const resultsAttributes = results.items.map((v) => v.attributes);
      dispatch(setTouchpointVersions({ ...results, items: resultsAttributes }));
      return results as PaginatedResponse<TouchpointVersion>;
    });
  };

  const isEmail = touchpointType === TouchpointType.EMAIL;

  const openTouchpointPreview = (id: string) => {
    const touchpointRoute = isEmail ? AppRoute.emailBuilder : AppRoute.lpBuilder;

    history.push(`${baseCreativeUrl}/versions/${id}${touchpointRoute}${AppRoute.preview}`);
  };

  const openTouchpointEdit = (touchpoint: TouchpointVersion) => {
    const touchpointRoute = isEmail ? AppRoute.emailBuilder : AppRoute.lpBuilder;

    history.push(`${baseCreativeUrl}/versions/${touchpoint.id}${touchpointRoute}`);
  };

  const handleCreateDraftFromVersion = () => {
    // 1. Fetched available next version #s using currentActionItem
    Touchpoint.nextVersions({
      clientId: currentClient.id,
      campaignId: currentCampaign.id,
      id: currentTouchpoint.id,
    })
      .then((response) => {
        let { nextMinorVersion, nextMajorVersion } = response.data;
        setNextMinorMajorVersions([nextMinorVersion, nextMajorVersion]);
      })
      .then(() => touchpointNewDraftModal.onOpen())
      .catch((err) => {
        toast.error({
          title: "Failed to create draft",
          message: getErrorMessage(err?.response?.data),
        });
      });
  };

  const handleEditVersion = ({ id, version, versionNotes, visibleToClient }: onSubmitFormProps) => {
    if (id) {
      TouchpointVersion.edit({
        clientId: currentClient.id,
        campaignId: currentCampaign.id,
        touchpointId: currentTouchpoint.id,
        id: id,
        attributes: {
          version: version,
          versionNotes: versionNotes,
          visibleToClient: visibleToClient,
        },
      })
        .then(() => {
          toast.success({
            title: "Version details updated",
          });
          refreshTable();
        })
        .catch((err) => {
          toast.error({
            title: "Failed to edit version details",
            message: getErrorMessage(err?.response?.data),
          });
        });
    }
    touchpointEditDraftModal.onClose();
  };

  const handleNewDraftSubmit = ({
    id,
    version,
    versionNotes,
    visibleToClient,
  }: onSubmitFormProps) => {
    if (id) {
      TouchpointVersion.createDraft({
        campaignId: currentCampaign.id,
        clientId: currentClient.id,
        touchpointId: currentTouchpoint.id,
        id: id,
        version: version,
        versionNotes: versionNotes,
        visibleToClient: visibleToClient,
      })
        .then(() => {
          toast.success({
            title: "New draft created",
          });
          refreshTable();
        })
        .catch((err) => {
          toast.error({
            title: "Failed to create draft",
            message: getErrorMessage(err?.response?.data),
          });
        });
    }
    touchpointNewDraftModal.onClose();
  };

  const handlePublishVersion = (id?: string) => {
    if (id) {
      TouchpointVersion.patchTouchpointStatus({
        clientId: currentClient.id,
        campaignId: currentCampaign.id,
        touchpointId: currentTouchpoint.id,
        touchpointVersionId: id,
        status: ContentStatus.PUBLISHED,
      })
        .then(() => {
          toast.success({
            title: "Published Touchpoint",
          });
          refreshTable();
        })
        .catch((err) => {
          toast.error({
            title: "Failed to publish Touchpoint",
            message: getErrorMessage(err?.response?.data),
          });
        });
    }
    confirmPublishModal.onClose();
  };

  const handleArchiveVersion = (touchpoint?: TouchpointVersion) => {
    if (touchpoint) {
      const touchpointType = getTouchpointType(touchpoint);
      const formattedTouchpointType = getDisplayTouchpointType(touchpointType);

      TouchpointVersion.patchTouchpointStatus({
        campaignId: currentCampaign.id,
        clientId: currentClient.id,
        touchpointId: currentTouchpoint.id,
        touchpointVersionId: touchpoint.id,
        status: ContentStatus.ARCHIVED_DRAFT,
      })
        .then(() => {
          refreshTable();
          toast.success({
            title: `${formattedTouchpointType} version ${touchpoint.version} archived.`,
          });
        })
        .catch((err) => {
          toast.error({
            title: "Failed to archive Touchpoint",
            message: getErrorMessage(err?.response?.data),
          });
        });
    }
    confirmArchiveModal.onClose();
  };

  const renderMenuItems = (version: TouchpointVersion) => {
    const isDraft = version.status === ContentStatus.DRAFT;
    const isSystemDraft = version.status === ContentStatus.SYSTEM_DRAFT;
    const isEditable = isDraft || isSystemDraft;
    const isEmail = touchpointType === TouchpointType.EMAIL;
    const formattedTouchpointType = getDisplayTouchpointType(touchpointType);

    const menuItems: {
      testId: string;
      show: boolean;
      icon: IconName;
      label: string;
      onClick: () => void;
    }[] = [
      {
        testId: "menu-preview-button",
        show: !isEditable,
        icon: "EyeVisible",
        label: `Preview ${formattedTouchpointType}`,
        onClick: () => openTouchpointPreview(version.id),
      },
      {
        testId: "menu-edit-version-button",
        show: isEditable,
        icon: "Document",
        label: "Edit version details",
        onClick: () => {
          setCurrentActionItem(version);
          touchpointEditDraftModal.onOpen();
        },
      },
      {
        testId: "menu-edit-button",
        show: isEditable,
        icon: "Edit",
        label: `Edit ${formattedTouchpointType}`,
        onClick: () => openTouchpointEdit(version),
      },
      {
        testId: "menu-create-draft-button",
        show: true,
        icon: "NewDocument",
        label: "Create draft from this version",
        onClick: () => {
          setCurrentActionItem(version);
          handleCreateDraftFromVersion();
        },
      },
      {
        testId: "menu-clone-button",
        show: true,
        icon: "CopyDocument",
        label: "Clone touchpoint",
        onClick: () => {
          setVersionToClone(version);
          cloneTouchpointModal.onOpen();
        },
      },
      {
        testId: "menu-publish-button",
        show: isEditable && hasPermission(currentUser, Permission.PERM_TOUCHPOINT_PUBLISH),
        icon: "Upload",
        label: isEmail ? "Publish to Iterable" : "Publish",
        onClick: () => {
          setCurrentActionItem(version);
          confirmPublishModal.onOpen();
        },
      },
      {
        testId: "menu-archive-button",
        show: isEditable,
        icon: "Archive",
        label: "Archive Draft",
        onClick: () => {
          setCurrentActionItem(version);
          confirmArchiveModal.onOpen();
        },
      },
    ];

    return menuItems
      .filter((item) => item.show)
      .map((item) => (
        <MenuItem
          key={item.testId}
          data-testid={item.testId}
          icon={item.icon}
          onClick={item.onClick}>
          {item.label}
        </MenuItem>
      ));
  };

  return (
    <>
      <PaginatedTable
        headers={["Version", "Description", "Status", "Actions"]}
        fetchPage={loadVersionHistory}
        shouldRefresh={shouldRefreshTable}
        setShouldRefresh={setShouldRefreshTable}>
        {(version) => {
          const isDraft = version.status === ContentStatus.DRAFT;
          const isSystemDraft = version.status === ContentStatus.SYSTEM_DRAFT;

          const isEditable = isDraft || isSystemDraft;

          return (
            <TableRow>
              {/* Version */}
              <StatusColumn
                id={version.id}
                status={version.status}
                version={version.version}
                lastModifiedDate={
                  version.status === ContentStatus.PUBLISHED
                    ? version.publishedAt ?? version.lastModifiedDate
                    : version.lastModifiedDate
                }
              />

              {/* Description */}
              <TableCell data-testid={`${version.id}-table-version-notes`}>
                {version.versionNotes}
              </TableCell>

              {/* Status */}
              <ClientReviewStatusColumn status={version.status as ContentStatus} />

              {/* Actions */}
              <TableCell>
                <HStack justifyContent={"flex-end"}>
                  {isEditable && (
                    <TableEditButton
                      title={`Edit ${getDisplayTouchpointType(touchpointType)} Version`}
                      aria-label={`Edit ${getDisplayTouchpointType(touchpointType)} Version`}
                      onClick={() => openTouchpointEdit(version)}
                    />
                  )}
                  <TableViewButton
                    title={`Preview ${getDisplayTouchpointType(touchpointType)} Version`}
                    aria-label={`Preview ${getDisplayTouchpointType(touchpointType)} Version`}
                    onClick={() => openTouchpointPreview(version.id)}
                  />
                  <Box>
                    <Menu isLazy>
                      <TableMoreButton
                        as={MenuButton}
                        title="More actions"
                        aria-label="More actions"
                        sx={{ "& > span": { display: "flex", justifyContent: "center" } }}
                      />
                      <MenuList className="!p-1 !shadow-sm">{renderMenuItems(version)}</MenuList>
                    </Menu>
                  </Box>
                </HStack>
              </TableCell>
            </TableRow>
          );
        }}
      </PaginatedTable>

      <VersionEditModal
        {...touchpointNewDraftModal}
        baseUrl={baseCreativeUrl}
        formType={FormType.NEW_VERSION}
        onSubmit={handleNewDraftSubmit}
        version={currentActionItem?.version || "1.0"}
        nextMinorMajorVersions={nextMinorMajorVersions}
        name={creativeName}
        status={currentActionItem?.status}
        parentName={currentCampaign.name}
        id={currentActionItem?.id}
        // Users must specifically make new drafts visible to client
        visibleToClient={false}
      />

      <VersionEditModal
        {...touchpointEditDraftModal}
        placeholderNotes={currentActionItem?.versionNotes}
        baseUrl={baseCreativeUrl}
        formType={FormType.EDIT}
        version={currentActionItem?.version || "1.0"}
        name={creativeName}
        onSubmit={handleEditVersion}
        nextMinorMajorVersions={nextMinorMajorVersions}
        helperText={"Edit the version description"}
        saveText={"Save"}
        status={currentActionItem?.status}
        parentName={currentCampaign.name}
        id={currentActionItem?.id}
        visibleToClient={currentActionItem?.visibleToClient}
      />
      <ConfirmArchiveModal
        {...confirmArchiveModal}
        currentTouchpoint={currentActionItem}
        onSubmit={() => handleArchiveVersion(currentActionItem)}
      />

      <CloneTouchpointModal {...cloneTouchpointModal} selectedVersion={versionToClone} />

      <ConfirmationModal
        {...confirmPublishModal}
        onConfirm={() => {
          handlePublishVersion(currentActionItem?.id);
        }}
        headline={
          touchpointType === "EMAIL"
            ? "Are you sure you want to publish this email to iterable"
            : "Are you sure you want to publish this landing page"
        }
        message={"This will replace the current published version. This action cannot be undone"}
        confirmButtonText={touchpointType === "EMAIL" ? "Yes, publish to iterable" : "Yes, publish"}
      />
    </>
  );
}

export default TouchpointVersionTable;
