import React from "react";
import { twMerge } from "tailwind-merge";
import Icons, { IconName } from "assets";

interface MenuItemProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  icon?: IconName;
}

const baseClassNames = `
  flex items-center py-2 px-3 w-full rounded-md
  text-base text-neutral-2 
  hover:bg-green-2 hover:text-neutral-1
`;

const MenuItem = ({ children, className, icon, ...rest }: MenuItemProps) => (
  <button className={twMerge(baseClassNames, className)} {...rest}>
    {icon && React.createElement(Icons[icon], { className: "mr-2 flex-shrink-0 w-5 h-5" })}
    <span className="flex-grow text-left">{children}</span>
  </button>
);

export default MenuItem;
