import { themeColors } from "themes/apollo/colors";

const s1 = "rgba(0, 0, 0, 0.14)";
const s2 = "rgba(0, 0, 0, 0.12)";
const s3 = "rgba(0, 0, 0, 0.20)";

const inner = `inset 0px 1px 3px ${s3}, inset 0px 2px 1px ${s2}, inset 0px 1px 1px ${s1}`;
const outer = inner.split("inset ").join("");

const shadows = {
  inner,
  outer,
  error: `0 0 0 2px ${themeColors.forms.errorBorderColor}!important`,
  outline: `0 0 0 2px ${themeColors.forms.focusBorderColor}`,
  sm: `0px 2px 2px ${s1}, 0px 3px 1px ${s2}, 0px 1px 5px ${s3}`,
  md: `0px 4px 5px ${s1}, 0px 1px 10px ${s2}, 0px 2px 4px ${s3}`,
  lg: `0px 8px 10px ${s1}, 0px 3px 14px ${s2}, 0px 5px 5px ${s3}`,
  xl: `0px 24px 38px ${s1}, 0px 9px 46px ${s2}, 0px 11px 15px ${s3}`,
  "3d": `0 1px 3px ${s3}, 0px 2px 1px ${s2}, 0px 1px 1px ${s1}`,
};

export default shadows;
