import classNames from "classnames";
import { twMerge } from "tailwind-merge";

/**
 * Create an array of CSS styles, pulled from an HTML string with <style> tags */
export function getStylesWithinHtmlStyleTags(html: string) {
  /** Matches `<style>...</style>` and `<style type="text/css">...</style>`  */
  const styleTagRegex = /<style[^>]*>([^<]+)<\/style>/gi;

  const arrayOfStyles = Array.from(html.matchAll(styleTagRegex), (tag) => {
    // tag[1] is the string of styles between <style></style>
    return tag[1];
  });

  return arrayOfStyles;
}

/**
 * Remove whitespace between CSS selectors but between parent/child selectors.
 * Example: `.box .button { display: block !important } ` becomes `.box .button{display:block!important}` */
export function compressStyles(styles: string) {
  /**
   * Characters which may have whitespace around them in CSS */
  const targetsArray = ",:{}()!*".split("");
  /**
   * Pre/postfix targetsArray with whitespace and escape characters
   * Example: [' \\:', '\\{ ', ' \\{', '\\} ', ' \\}', '\\! ', ' \\!'] */
  const escapedTargets = targetsArray.flatMap((target) => {
    return ["\\" + target + " ", " \\" + target];
  });
  /**
   * Remove the escape characters to use in replacementMap.
   * Example: `['{ ', ' {', '} ', ' }', '( ', ' (', ') ', ' )]` */
  const whitespacedTargets = escapedTargets.map((target) => {
    return target.replace("\\", "");
  });

  /**
   * Create an object of key/value pairs for use in replace method below
   * Example: `{" (": '(', "( ": '(', " :": ':', ": ": ':'}` */
  const replacementMap = Object.fromEntries(
    whitespacedTargets.map((target) => [target, target.trim()])
  );
  /**
   * A regex similar to this:  `/( {|{ | }|} | ,|, | :|: )/gi` */
  const replacementRegex = new RegExp(`(${escapedTargets.join("|")})`, "gi");
  /**
   * If a character matching a key from replacementMap is found, replace it with that key's value */
  const compressedStyles = styles
    .replace(replacementRegex, (value) => replacementMap[value])
    // I don't know why this isn't covered but it's not.
    .replaceAll("{ ", "{");

  return compressedStyles;
}

/** Strips all comments from the string */
export function removeComments(styles: string) {
  /** Matches CSS Comments =>
   * [Finding Comments via RegEx](https://shorturl.at/qX028) */
  const commentRegex = /\/\*([^*]|[\r\n]|(\*+([^*/]|[\r\n])))*\*\//g;
  const removedComments = styles.replace(commentRegex, "");
  return removedComments;
}

/**
 * Replaces root, body and html selectors with prefix */
export function replaceRootWordsWithPrefix(styles: string, prefix: string) {
  /** Matches `html`, `body` and `:root` selectors */
  const rootRegex = /[^-](html|body)|(:root)(?={|,)/g;

  const replacedRootWords = styles.replace(rootRegex, (match) => {
    const firstChar = match.charAt(0);
    return ["}", " "].includes(firstChar) ? `${firstChar}${prefix}` : prefix;
  });
  return replacedRootWords;
}

/** Inserts prefix before selector the follows a closing brace */
export function prefixSelectorsThatFollowBraces(styles: string, prefix: string) {
  /** Matches closing braces `}` that are not inside media queries, not already prefixed, and not the last character of the string */
  const braceRegex = new RegExp(`}(?!}|@|${prefix})(?=.)`, "g");
  const replacedBraces = styles.replace(braceRegex, `$&${prefix}`);
  return replacedBraces;
}

/** Inserts prefix before selector that follows a comma */
export function prefixSelectorsThatFollowCommas(styles: string, prefix: string) {
  /** Matches commas not between braces */
  const commaRegex = /,(?![^{}]*})/g;
  const replacedCommas = styles.replace(commaRegex, `$&${prefix}`);
  return replacedCommas;
}

/** Inserts prefix before selector within a media query */
export function prefixSelectorsWithinMediaQueries(styles: string, prefix: string) {
  /** Matches braces that are inside media queries and not already prefixed */
  const mediaQueryRegex = new RegExp(`@media[^{]+{(?!${prefix})`, "g");
  const replacedMQs = styles.replace(mediaQueryRegex, `$&${prefix}`);
  return replacedMQs;
}

/** Prefix the entire string if it's already prefixed */
export function prefixOrReturnString(styles: string, prefix: string) {
  const firstWordOfString = styles.slice(0, prefix.length);

  const prefixedString =
    firstWordOfString === prefix || firstWordOfString.includes("@font-face")
      ? styles
      : prefix + styles;
  return prefixedString;
}

/**
 * Prevent styles from affecting child elements of specified parent
 * @param selector Any CSS selector. The "parent".
 * @returns p:not(.selector, .selector p) { ...styles }
 */
export function excludeStylesWithinSelector(styles: string, prefix: string, selector: string) {
  /** Targets child elements (p, h1, strong), types ([type="button"]) and pseudo-elements (::before). Does not target classes, IDs, etc. */
  const childElementsRegex = new RegExp(`(${prefix})([\\w\\d[\\]=":*-]+(?=,|{|\\s))`, "g");

  const stylesWithExclusions = styles.replace(childElementsRegex, (match) => {
    const childElement = match.split(" ")[1];
    const childSelector = `${selector} ${childElement}`;
    const notSelector = `${match}:not(${selector}, ${childSelector})`;
    return notSelector;
  });

  return stylesWithExclusions;
}

/**
 * Combines classnames using `classnames` and merges the result with `twMerge` to optimize Tailwind CSS class utilities.
 * This function ensures that the resulting class string is free of duplicate or conflicting Tailwind utility classes.
 *
 * @param {classNames.ArgumentArray} args - An array of class values that can be strings, objects, or arrays that will be processed by `classnames`.
 * @returns {string} A string that represents the merged and optimized class names for use in a className attribute in React components.
 */
export const classMerge = (...args: classNames.ArgumentArray): string => {
  return twMerge(classNames(args));
};
