import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { MdEdit } from "react-icons/md";
import { Button, HStack, IconButton, InputRightElement } from "@chakra-ui/react";

import Tooltip from "components/partials/tooltip/tooltip";
import Input, { TextInputProps } from "components/forms/input/input";

import { useCurrentUser } from "state/ducks/users";
import { setCurrentClient } from "state/ducks/clients";
import Client from "models/client";

import { hasPermission } from "utilities/user";

import { Permission } from "types/auth";

interface MaskedInputProps extends TextInputProps {
  isLocked?: boolean;
  incomingValue?: string;
  clientId: string;
}

export const MaskedInput = ({
  isLocked,
  incomingValue,
  id,
  clientId,
  ...rest
}: MaskedInputProps) => {
  const currentUser = useCurrentUser();
  const dispatch = useDispatch();

  const hasEditPermission = hasPermission(currentUser, Permission.PERM_CLIENT_ADVANCED);

  const [value, setValue] = useState(incomingValue);
  const [isEditable, setIsEditable] = useState(!isLocked);

  useEffect(() => {
    if (isEditable) {
      // If the input becomes editable, clear the value
      setValue("");
    } else if (value === "") {
      // If the input is no longer editable and there is no value
      // set it back to the original incomingValue
      setValue(incomingValue);
    }
    // eslint-disable-next-line
  }, [isEditable]);

  const EditButton = () => (
    <Tooltip label="Edit" placement="top">
      <IconButton
        variant="link"
        minWidth="none"
        padding={2}
        size="lg"
        aria-label="Edit Iterable API Key"
        icon={<MdEdit />}
        onClick={() => setIsEditable(true)}
      />
    </Tooltip>
  );

  const CancelButton = () => {
    function handleCancel() {
      setValue(incomingValue);
      setIsEditable(false);
    }
    return (
      <Button variant="outline" colorScheme="blackAlpha" size="sm" onClick={handleCancel}>
        Cancel
      </Button>
    );
  };

  const SaveButton = () => {
    function handleSave() {
      Client.updateIterableApiKey(clientId, value!).then((res) => {
        dispatch(setCurrentClient(res.attributes));
      });
      setIsEditable(false);
    }
    return (
      <Button
        variant="solid"
        isDisabled={!value}
        colorScheme="blackAlpha"
        size="sm"
        onClick={handleSave}>
        Save
      </Button>
    );
  };

  return (
    <Input
      value={value}
      onChange={(e) => setValue(e.target.value)}
      placeholder={incomingValue}
      _placeholder={{ opacity: 0.25 }}
      isDisabled={isLocked && !isEditable}
      pr={isEditable ? "9rem" : "2.5rem"}
      inputRightElement={
        hasEditPermission ? (
          <InputRightElement
            width="auto"
            marginRight={2}
            children={
              isEditable ? (
                <HStack spacing={2} background="white">
                  <CancelButton />
                  <SaveButton />
                </HStack>
              ) : (
                <EditButton />
              )
            }
          />
        ) : undefined
      }
      {...rest}
    />
  );
};
