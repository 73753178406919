import { hexToRGBA, lightenDarkenColor } from "utilities";

let aqua = {
    light: "#19D3C5",
    100: "#19D3C5",
    dark: "#0D736A",
    200: "#0D736A",
  },
  azure = {
    light: "#3CB4E5",
    100: "#3CB4E5",
    dark: "#0F6385",
    200: "#0F6385",
  },
  gray = {
    5: "#F5F5F5",
    100: "#F5F5F5",
    4: "#E4E5E7",
    200: "#E4E5E7",
    3: "#858B93",
    300: "#858B93",
    2: "#5B6470",
    400: "#5B6470",
    1: "#323B48",
    500: "#323B48",
  },
  navy = {
    200: "#002E6D",
  },
  offWhite = "#FCFCFC",
  scarlett = {
    light: "#FF595A",
    100: "#FF595A",
    dark: "#C20000",
    200: "#C20000",
  },
  success = {
    100: "#05B56C",
    200: "#01683D",
  },
  info = {
    100: "#2FC9FC",
    200: "#0E5592",
  },
  warning = {
    100: "#FFAB0F",
    200: "#B76D07",
  },
  error = {
    100: "#FF533D",
    200: "#B71E25",
  };

/**
 * Shouldn't be imported to components,
 * but has been, so this export has to stay. */
export const themeColors = {
  trueWhite: "#FFFFFF",
  white: offWhite,
  black: gray[500],
  gray: gray,
  primary: navy[200],
  secondary: azure,
  accent: scarlett,
  highlight: aqua,
  muted: {
    100: offWhite,
    200: gray[100],
    300: gray[200],
    400: gray[300],
  },
  success: success,
  info: info,
  warning: warning,
  error: error,
  danger: error,
  text: {
    body: gray[500],
    light: offWhite,
    muted: gray[400],
    heading: navy[200],
    link: aqua[200],
    accent: scarlett[200],
    success: success[200],
    info: info[200],
    warning: warning[200],
    error: error[200],
  },
  bg: {
    light: offWhite,
    content: gray[100],
    dark: gray[200],
  },
  forms: {
    inputBg: offWhite,
    inputDisabledBg: gray[200],
    inputColor: gray[500],
    focusBorderColor: hexToRGBA(info[100], "0.5"),
    errorBorderColor: error[100],
  },
};

/**
 * Generates a color scheme that matches Chakra's button mapping
 * Allows use of "colorScheme" prop on buttons
 * TODO: Fill gaps to expand use beyond buttons
 * */
function createButtonColorScheme(color: { [200]: string; [key: number]: string }) {
  return {
    /** 
    50 = Outline and Ghost button :hover background  */
    50: offWhite,

    /** 
    100 = Outline and Ghost button :active background  */
    100: offWhite,

    /** 
    500 = Solid button bg color; Link button color  */
    500: color[200],

    /** 
    600 = Solid button :hover bg color; Outline, Ghost & Link color  */
    600: lightenDarkenColor(color[200], 25),

    /** 
    700 = Solid button :active bgColor; Link :active color  */
    700: color[200],
  };
}

const colorSchemes = {
  aqua: createButtonColorScheme(aqua),
  navy: createButtonColorScheme(navy),
  scarlett: createButtonColorScheme(scarlett),
};

/**
 * Deprecation in progress.
 * Buttons should use colorScheme
 */
const buttons = {
  primary: {
    bg: colorSchemes.aqua[500],
    text: colorSchemes.aqua[50],
  },
  danger: {
    bg: colorSchemes.scarlett[500],
    text: colorSchemes.scarlett[50],
  },
  secondary: {
    bg: colorSchemes.aqua[50],
    text: colorSchemes.aqua[500],
  },
  tertiary: {
    bg: colorSchemes.aqua[50],
    text: colorSchemes.aqua[500],
  },
  link: {
    bg: "transparent",
    text: colorSchemes.aqua[500],
  },
  navy: {
    bg: colorSchemes.navy[500],
    text: colorSchemes.navy[50],
  },
};

/**
 * These are the variants Chakra uses
 * */
const chakraButtons = {
  solid: buttons.primary,
  outline: buttons.secondary,
  ghost: buttons.tertiary,
};

const colors = {
  ...themeColors,
  buttons: { ...buttons, ...chakraButtons },
  // ...chakraButtons,
  ...colorSchemes,
};

export default colors;
