import { useEffect, useState } from "react";
import {
  ModalProps,
  Modal,
  Box,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  HStack,
  Alert,
  AlertIcon,
  AlertTitle,
} from "@chakra-ui/react";

import Input from "components/forms/input/input";

import Textarea from "components/forms/textarea/textarea";
import { H3, H5, Span, ExtraSmall } from "components/partials/typography/typography";
import Button from "components/forms/button/button";

import { useCurrentUser } from "state/ducks";

import { hasPermission } from "utilities/user";

import { Permission } from "types/auth";

export interface onSubmitFormProps {
  id?: string;
  version: string;
  versionNotes: string;
  visibleToClient?: boolean;
}

export enum FormType {
  NEW = "new",
  EDIT = "edit",
  NEW_VERSION = "newVersion",
}

export enum ReleaseType {
  MAJOR = "Major",
  MINOR = "Minor",
}

interface VersionEditModalProps {
  baseUrl: string;
  parentName?: string;
  formType: FormType;
  helperText?: string;
  id?: string;
  isOpen: ModalProps["isOpen"];
  name?: string;
  nextMinorMajorVersions?: string[];
  onClose: ModalProps["onClose"];
  onSubmit?: ({ id, version, versionNotes, visibleToClient }: onSubmitFormProps) => void;
  placeholderNotes?: string;
  saveText?: string;
  status?: string;
  version: string;
  visibleToClient?: boolean;
}

const VersionEditModal = ({
  isOpen,
  onClose,
  version,
  formType,
  name,
  onSubmit,
  nextMinorMajorVersions,
  placeholderNotes = "",
  helperText,
  saveText,
  status,
  parentName,
  id,
  visibleToClient: api_visibleToClient = false,
}: VersionEditModalProps) => {
  const currentUser = useCurrentUser();

  const [formError, setFormError] = useState<string>();

  // TODO: versionNotes and visibleToClient are duplicate states.
  // We should get their values from Redux in the
  const [versionNotes, setVersionNotes] = useState<string>(placeholderNotes);
  const [visibleToClient, setVisibleToClient] = useState<boolean>(api_visibleToClient);

  useEffect(() => {
    setVersionNotes(placeholderNotes || "");
  }, [isOpen, placeholderNotes]);

  useEffect(() => {
    setVisibleToClient(api_visibleToClient);
  }, [api_visibleToClient]);

  const isNewFormType = formType === FormType.NEW;
  const isEditFormType = formType === FormType.EDIT;
  const isNewVersionFormType = formType === FormType.NEW_VERSION;
  const isClientUser = hasPermission(currentUser, Permission.PERM_CLIENT_USER);

  const [releaseType, setReleaseType] = useState<ReleaseType>(
    isNewFormType ? ReleaseType.MAJOR : ReleaseType.MINOR
  );
  const isMajorRelease = releaseType === ReleaseType.MAJOR;
  const isMinorRelease = releaseType === ReleaseType.MINOR;

  const handleCloseModal = () => {
    setReleaseType(isNewFormType ? ReleaseType.MAJOR : ReleaseType.MINOR);
    setVersionNotes("");
    setFormError(undefined);
    onClose();
  };

  const handleSubmit = () => {
    if (versionNotes === "") {
      setFormError("Description is required.");
      return;
    }

    if (onSubmit) {
      if (isNewFormType || isEditFormType) {
        onSubmit({ id, version, versionNotes, visibleToClient });
      }

      if (isNewVersionFormType && nextMinorMajorVersions && (isMinorRelease || isMajorRelease)) {
        onSubmit({
          id,
          version: nextMinorMajorVersions[isMinorRelease ? 0 : 1],
          versionNotes,
          visibleToClient,
        });
      }
    }
    setVersionNotes("");
    setFormError(undefined);
  };

  const saveButtonText = saveText || "Save draft & continue";

  return (
    <Modal isOpen={isOpen} onClose={handleCloseModal} closeOnOverlayClick={false} size="xl">
      <ModalOverlay />
      <ModalContent borderStyle="solid" borderColor="transparent" borderLeftWidth={8}>
        <Box>
          <ModalHeader pt={6} pb={2}>
            <H3 data-testid="version-modal-header">
              {isNewFormType
                ? "Create a new draft"
                : isEditFormType
                ? "Edit version details"
                : isNewVersionFormType && `Create a new draft from v${version}`}
            </H3>
            <ModalCloseButton />
            <H5 mt={4}>
              {parentName && <Span data-testid="version-modal-campaign-name">{parentName} | </Span>}
              <Span data-testid="version-modal-name" ml={1}>
                {name ? name : ""}
              </Span>
            </H5>
          </ModalHeader>

          <ModalBody>
            {helperText ? (
              <Span data-testid="version-modal-helper-text">{helperText}</Span>
            ) : (
              <Span>
                Enter a description below to create a new version in draft mode. you'll be able to
                edit the description or publish this version later.
              </Span>
            )}
            <br />
            <ExtraSmall>All fields are required.</ExtraSmall>
            {formError && (
              <Alert mr={5} status="error">
                <AlertIcon />
                <AlertTitle>{formError}</AlertTitle>
              </Alert>
            )}
            <HStack mt={6} mb={8} justify="space-between">
              <Input
                data-testid="version-modal-release-type"
                isDisabled
                label="Type of release"
                value={releaseType}
              />
              <Spacer />
              <Input
                data-testid="version-modal-input-version"
                isDisabled
                label="Version number"
                value={
                  isNewFormType || isEditFormType
                    ? version
                    : isNewVersionFormType && isMinorRelease
                    ? nextMinorMajorVersions && nextMinorMajorVersions[0]
                    : nextMinorMajorVersions && nextMinorMajorVersions[1]
                }
              />
            </HStack>

            <Textarea
              data-testid="version-modal-input-description"
              helperText="Write a short description of this version"
              isDisabled={isClientUser}
              label="Description"
              onChange={(e) => setVersionNotes(e.target.value)}
              resize="none"
              rows={2}
              value={versionNotes}
            />
          </ModalBody>

          <ModalFooter pb={8} pt={2}>
            <Button
              data-testid="version-modal-cancel-button"
              variant="tertiary"
              mr={5}
              onClick={handleCloseModal}>
              Cancel
            </Button>
            <Button
              data-testid="version-modal-save-button"
              isDisabled={isClientUser}
              onClick={handleSubmit}>
              {saveButtonText}
            </Button>
          </ModalFooter>
        </Box>
      </ModalContent>
    </Modal>
  );
};

export default VersionEditModal;
