import { useEffect, useState } from "react";

import { useCurrentClient } from "state/ducks";

import { Campaign } from "models";

import { DEFAULT_PAGINATED_REQUEST_OPTIONS } from "types/pagination";

export const useClientCampaigns = (options = DEFAULT_PAGINATED_REQUEST_OPTIONS) => {
  const [clientCampaigns, setClientCampaigns] = useState<Campaign[]>([]);
  const currentClient = useCurrentClient();

  useEffect(() => {
    const fetchCampaigns = async () => {
      try {
        const clientCampaigns = await Campaign.all({
          options,
          clientId: currentClient.id,
        });
        setClientCampaigns(clientCampaigns.items);
      } catch (error) {
        console.error("Failed to fetch campaigns", error);
      }
    };
    fetchCampaigns();
  }, [currentClient.id, options]);

  return clientCampaigns;
};
