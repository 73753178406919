import { IconButton, IconButtonProps } from "@chakra-ui/react";
import { MdRemoveRedEye, MdEdit, MdMoreVert, MdFileDownload, MdContentCopy } from "react-icons/md";

import Tooltip from "components/partials/tooltip/tooltip";

interface TableIconButtonProps extends IconButtonProps {
  title?: string;
}
const TableIconButton = ({
  title,
  variant = "link",
  size = "lg",
  fontSize = "xl",
  color = "buttons.primary.bg",
  ...rest
}: TableIconButtonProps) => {
  return title ? (
    <Tooltip label={title}>
      <IconButton variant={variant} size={size} fontSize={fontSize} color={color} {...rest} />
    </Tooltip>
  ) : (
    <IconButton variant={variant} size={size} fontSize={fontSize} color={color} {...rest} />
  );
};

export default TableIconButton;

export const TableViewButton = ({ ...rest }: TableIconButtonProps) => (
  <TableIconButton
    data-testid={"table-view-button"}
    icon={<MdRemoveRedEye />}
    title="View"
    {...rest}
  />
);

export const TableEditButton = ({ ...rest }: TableIconButtonProps) => (
  <TableIconButton data-testid={"table-edit-button"} icon={<MdEdit />} title="Edit" {...rest} />
);

export const TableMoreButton = ({ ...rest }: TableIconButtonProps) => (
  <TableIconButton data-testid={"table-more-button"} icon={<MdMoreVert />} title="More" {...rest} />
);

export const TableDownloadButton = ({ ...rest }: TableIconButtonProps) => (
  <TableIconButton
    data-testid={"table-download-button"}
    icon={<MdFileDownload />}
    title="Download"
    {...rest}
  />
);

export const TableCopyButton = ({ ...rest }: TableIconButtonProps) => (
  <TableIconButton
    data-testid={"table-copy-button"}
    icon={<MdContentCopy />}
    title="Copy"
    {...rest}
  />
);
