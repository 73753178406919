import { useDispatch, useSelector } from "react-redux";

import { useCurrentCampaign, useCurrentClient } from "state/ducks";
import {
  setTouchpointVersions,
  touchpointVersionsSelector,
  useCurrentTouchpoint,
} from "state/ducks/touchpoint";
import TouchpointVersion, { TouchpointVersionAttributes } from "models/touchpoint-version";

import { GeneralCommentsFAB } from "components/partials/general-comments-fab/general-comments-fab";
import { TouchpointPreviewTabContent } from "containers/admin/clients/touchpoint/tab-components/preview-tab/components/preview-tab-content/preview-tab-content";
import { TouchpointPreviewTabHeader } from "containers/admin/clients/touchpoint/tab-components/preview-tab/components/preview-tab-header/preview-tab-header";

interface PreviewTabProps {
  isSummaryLoading?: boolean;
  baseCreativeUrl: string;
  selectedVersion: TouchpointVersionAttributes | undefined;
  setSelectedVersion: (version: TouchpointVersionAttributes) => void;
}

const PreviewTab = ({
  isSummaryLoading,
  baseCreativeUrl,
  selectedVersion,
  setSelectedVersion,
}: PreviewTabProps) => {
  const currentClient = useCurrentClient();
  const currentCampaign = useCurrentCampaign();
  const currentTouchpoint = useCurrentTouchpoint();
  const touchpointVersions = useSelector(touchpointVersionsSelector);
  const dispatch = useDispatch();

  const refreshTouchpointVersions = () => {
    TouchpointVersion.all({
      clientId: currentClient.id,
      campaignId: currentCampaign.id,
      touchpointId: currentTouchpoint.id,
    }).then((results) => {
      const resultsAttributes = results.items.map((v) => v.attributes);
      dispatch(setTouchpointVersions({ ...results, items: resultsAttributes }));
    });
  };

  return (
    <>
      {selectedVersion?.id && (
        <TouchpointPreviewTabHeader
          isSummaryLoading={isSummaryLoading}
          versions={touchpointVersions.items}
          setSelectedVersion={setSelectedVersion}
          selectedVersion={selectedVersion}
          baseCreativeUrl={baseCreativeUrl}
          refreshVersions={refreshTouchpointVersions}
        />
      )}
      <TouchpointPreviewTabContent
        selectedVersion={selectedVersion}
        baseCreativeUrl={baseCreativeUrl}
      />

      <GeneralCommentsFAB />
    </>
  );
};

export default PreviewTab;
